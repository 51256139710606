<template>
  <styled-div
    :breakpoint="breakpoint"
    :display="dropdownOpen ? 'block' : null"
    class="bannerMenu__dropdown-content"
   :style="tileNavigation && dropdownOpen && isMobile ? 'position: absolute; margin-top: 0.5rem; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; border-radius: 0.5rem; border: 0.25px solid #fff;' : ''"
  >
    <slot />
  </styled-div>
</template>

<script>
import styled from '@u-rogel/vue-emotion'
import headerMixins from '~/mixins/headerMixins'

const StyledDiv = styled.div`
  display: ${({ display }) => display || 'none'};
  background-color: var(--header-navbar-bg);
  min-width: 120px;
  z-index: 1;
  @media (min-width: ${({ breakpoint }) => breakpoint + 1 || 1025}px) {
    position: absolute;
  }
  @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    padding: .25rem;
  }
  span {
    display: flex;
    margin-left: .7rem;
    flex-direction: column;
    word-break: break-word;
    white-space: normal;
    max-width: fit-content;
    @media (min-width: ${({ breakpoint }) => breakpoint + 1 || 1025}px) {
      white-space: unset;
    }
  }
  *:not(span):not(svg):not(path) {
    color: var(--header-navbar-bg-contrast);
    display: block;
    padding: 7px 0;
    &:hover {
      color: var(--header-navbar-bg-contrast);
    }
    @media (min-width: ${({ breakpoint }) => breakpoint + 1 || 1025}px) {
      padding-left: 15px;
      padding-right: 15px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
      /* padding-left: 5px;
      padding-bottom: 10px;
      padding-top: 10px; */
      display: flex;
      align-items: center;
      flex-direction: row;

      &:last-child {
        //padding-bottom: 0;
      }
    }
  }
`({
  display: String,
  breakpoint: Number,
})

export default {
  components: {
    StyledDiv,
  },
  mixins: [
    headerMixins,
  ],
  computed: {
    tileNavigation() {
      if (this.headerConfig.tileNavigation) {
        return this.headerConfig.tileNavigation
      }
      return false
    },
    isMobile() {
      if (this.width < this.breakpoint) {
        return true
      }
      return false
    },
  },
  props: {
    dropdownOpen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    },
  },
}
</script>
