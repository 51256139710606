<template>
  <styled-div
    @click.stop.prevent="toggleDropdown"
    :breakpoint="breakpoint"
    class="bannerMenu__dropdown-container"
    :style="tileNavigation && isMobile ? 'padding: 0' : ''"
  >
    <text-icon-div
      :breakpoint="breakpoint"
      class="bannerMenu-dropdown"
      :style="tileNavigation && isMobile ? 'display: flex; flex-direction: column; align-items: center; margin-left: 0; position: relative' : ''"
    >
      <slot />
      <template v-if="tileNavigation && isMobile">
        <font-awesome-icon
          :class="tileNavigation && isMobile ? 'bannerMenu-dropdown-icon-tileNavigation' : ''"
          :icon="['fa',`circle-${dropdownOpen ? 'minus' : 'plus'}`]"
        />
      </template>
      <template v-else>
        <font-awesome-icon
          :class="tileNavigation && isMobile ? 'bannerMenu-dropdown-icon-tileNavigation' : ''"
          :icon="['fa',`chevron-${dropdownOpen ? 'down' : 'right'}`]"
        />
      </template>
    </text-icon-div>
    <dropdown-content
      ref="dropdownContent"
      @mouseleave="dropdownOpen = false"
      :dropdown-open="dropdownOpen"
      :breakpoint="breakpoint"
    >
      <component
        v-for="(item, index) in items"
        :key="index"
        :is="tag(item)"
        v-bind="tagProps(item)"
        @click="action(tagProps(item), item)"
        :class="navItemProps(item).active ? 'active' : null"
        class="bannerMenu__menu-item-dropdown-link"
        :hidden="!checkIsComponentTimeActiveHeader(item) ? true : (('showMenuItem' in item) ? !item.showMenuItem : false)"
      >
        <icon-wrapper :breakpoint="breakpoint" class="bannerMenu__menu-item-dropdown-icon">
          <template v-if="'iconImagePath' in item">
            <img
            v-if="isAppContainer"
              class="sub_menu_bannerMenu__menu-item-icon__icon-image-path"
              :src="item.iconImagePath"
            />
          </template>
          <template v-else-if="item.icon">
            <font-awesome-icon :icon="icon(item.icon)" />
          </template>
        </icon-wrapper>
        <span class="dropdown-navigation-title">{{ item.name }}</span>
      </component>
    </dropdown-content>
  </styled-div>
</template>

<script>
import styled from '@u-rogel/vue-emotion'
import tagMixins from '~/mixins/tagMixins'
import headerMixins from '~/mixins/headerMixins'
import appContainerMixins from 'm-anage.com.vmx-components/src/mixins/appContainerMixins'
import componentIsActiveMixins from '~/mixins/componentIsActiveMixins'
import DropdownContent from './DropdownContent.vue'
import {mapGetters} from "vuex";

const StyledDiv = styled.div`
  cursor: pointer;
  z-index: 1;
  @media (min-width: ${({ breakpoint }) => breakpoint + 1 || 1025}px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    &:hover {
      div {
        display: block;
      }
    }
    svg {
      display: none;
    }
    span {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`({
  breakpoint: Number,
})

const TextIconDiv = styled.div`
  @media (max-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    display: flex;
    align-items: center;
    svg {
      margin-left: auto;
    }
  }
`({
  breakpoint: Number,
  tileNavigationMargin: String,
})

const IconWrapper = styled.span`
  @media (min-width: ${({ breakpoint }) => breakpoint || 1024}px) {
    display: none;
  }
  display: inline-block;
  width: 21px;
  color: var(--header-navbar-bg-contrast);
  svg {
    margin-right: 5px;
  }

`({
  breakpoint: Number,
})

export default {
  components: {
    StyledDiv,
    DropdownContent,
    TextIconDiv,
    IconWrapper,
  },
  computed: {
    ...mapGetters({
      isAppContainer: 'instancesState/getIsAppContainer'
    }),
    tileNavigation() {
      if (this.headerConfig.tileNavigation) {
        return this.headerConfig.tileNavigation
      }
      return false
    },

    isMobile() {
      if (this.width < this.breakpoint) {
        return true
      }
      return false
    },
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    width: {
      type: Number,
      default: 0,
    },
    itemNr: {
      type: Number,
      default: 0,
    },
  },
  mixins: [
    tagMixins,
    headerMixins,
    appContainerMixins,
    componentIsActiveMixins.componentProps,
    componentIsActiveMixins.method,
  ],
  data() {
    return {
      dropdownOpen: false,
    }
  },
  methods: {
    toggleDropdown() {
        if(this.width < this.breakpoint) this.dropdownOpen = !this.dropdownOpen
    },
    action(props, item) {
      const pages = ['PosterPage', 'IndustrySymposiaPage', 'PresentationSearchPage', 'FacultySearchPage', 'PagSearchPage', 'AbstractSearchPage']
      if (pages.includes(this.$route.name)) {
        this.$root.$emit('rerender')
      }
      if ((item.dataType === 'link' || item.dataType === 'qnalink') && this.isAppContainer) {
        if (item.dataType === 'qnalink') {
          if(Vue.$cookies.get('access_token')) {
           window.open(`${item.href}?access_token=${Vue.$cookies.get('access_token')}&user_id=${Vue.$cookies.get('user_id')}&event_id=${Vue.$cookies.get('event_id')}&isApp=${this.isAppContainer}&returnUrl=${window.location.href}`, '_self')
          } else {
            window.open(`${item.href}/${item.qnaInstance}?isApp=${this.isAppContainer}&returnUrl=${window.location.href}`, '_self')

          }
          return
        }
        window.openLink(item.href)
        return
      }

      if (item.dataType === 'qnalink') {
        if(Vue.$cookies.get('access_token')) {
          window.open(`${item.href}?access_token=${Vue.$cookies.get('access_token')}&user_id=${Vue.$cookies.get('user_id')}&event_id=${Vue.$cookies.get('event_id')}&isApp=${this.isAppContainer}&returnUrl=${window.location.href}`, '_blank')
        } else {
          window.open(`${item.href}/${item.qnaInstance}?isApp=${this.isAppContainer}`, '_blank')
        }
      }
      if(item.isDownload && this.isAppContainer){
        this.openLink(item.url, 'blank')
        return
      }

      window.open(item.href, item.target)

    },
    isActive(item) {
      const url = item.url
      const activePath = this.activeRoute.path
      if (typeof url === 'object'
        && url.name === this.activeRoute.name
        && JSON.stringify(url.params) === JSON.stringify(this.activeRoute.params)) {
        return true
      }
      if (typeof url === 'string' && url.includes(activePath)) {
        return true
      }
      return this.activeRoute.name === item.url
    },
    navItemProps(item) {
      const props = {}
      if (this.isActive(item)) {
        this.$emit('subActive', this.itemNr)
        props.active = true
      }
      return props
    },
    icon(icon) {
      // eslint-disable-next-line prefer-const
      let [first, ...rest] = icon.split('-')
      rest = rest.join('-')
      if (first && rest) {
        return [first, rest]
      }
      return ['fa', 'question']
    },
  },
  watch: {
    width() {
      if (this.width > this.breakpoint) {
        this.dropdownOpen = false
      }
    },
  },
}
</script>
<style>

.sub_menu_bannerMenu__menu-item-icon__icon-image-path {
    height: 2em;
    overflow: visible;
  }

.bannerMenu-dropdown-icon-tileNavigation {
  position: absolute;
  right: 0;
  bottom: 0;
}
.tile-navigation .bannerMenu__my-area__container .bannerMenu__dropdown-container {
  padding: 10px 20px !important;
}
.tile-navigation .bannerMenu__my-area__container .bannerMenu__dropdown-container .bannerMenu-dropdown {
    display: block !important;
  }
  .tile-navigation .bannerMenu__my-area__container .bannerMenu__dropdown-container .bannerMenu__dropdown-content {
    position: unset !important;
    margin-top: unset !important;
    box-shadow: none !important;
    border: none !important;
    padding: 0.5rem 0 0 0;
  }
  .tile-navigation .bannerMenu__my-area__container .bannerMenu__dropdown-container .bannerMenu__menu-item-dropdown-icon {
    margin-right: 5px;
    margin-left: 0px;
  }
  .tile-navigation .bannerMenu__my-area__container .bannerMenu__dropdown-container .dropdown-navigation-title {
    margin-left: 0;
  }
  .tile-navigation .bannerMenu__customMenuItem-container .bannerMenu__dropdown-container {
    padding: 0 !important;
  }
</style>